<template>
  <div id="categories-page">
    <AppContent
      class="categories-content"
      :isFullPage="true"
      :isFluid="false"
      :isContentContainer="true"
      :withSidebar="true">
      <h2 class="text-center heading">CATEGORIES</h2>
      <br>
      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <b-row class="text-center">
            <b-col
              cols="6"
              lg="4"
              xl="3"
              v-for="index in perPage"
              :key="index">
              <b-skeleton-img
                animation="throb"
                width="250"
                height="250"
                aspect="1:1"
              ></b-skeleton-img>
              <div class="text-center mt-3 mb-3">
                <b-skeleton animation="throb" width="100%"></b-skeleton>
                <b-skeleton animation="throb" width="80%"></b-skeleton>
              </div>
            </b-col>
          </b-row>
        </template>

        <div>
          <b-row>
            <b-col
              cols="6"
              lg="4"
              xl="3"
              v-for="(categ, index) in categories"
              :key="index">
              <router-link class="link" :to="`category/${categ.category_id}/${generateSlug(categ.category_title)}`">
                <CategoryCard :data="categ" />
              </router-link>
            </b-col>
          </b-row>

          <div class="text-center mt-3">
            <b-button
              variant="primary"
              v-if="lastPage !== currPage"
              @click="loadMore">
              Load More
            </b-button>
          </div>
        </div>
      </b-skeleton-wrapper>
    </AppContent>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        loading    : false,
        currPage   : 1,
        lastPage   : null,
        perPage    : 8,
        categories : [],
      }
    },
    components : {
      'AppContent'   : () => import('@/components/layout/AppContent'),
      'CategoryCard' : () => import('./CategoryCard'),
    },
    methods : {

      /**
       * Get Categories
       */
      getCategories() {
        this.loading = true;
        this.$http.get('api/categories', {
          params : {
            page    : this.currPage,
            perPage : this.perPage,
          },
        }).then(response => {
          this.currPage = response.data.currentPage;
          this.lastPage = response.data.lastPage;

          if (this.currPage > 1) {
            const arr = response.data.data;
            this.categories = this.categories.concat(arr);
          } else
            this.categories = response.data.data;
        }).catch(() => {
          this.categories = [];
        }).finally(() => {
          setInterval(() => {
            this.loading = false;
          }, 1500);
        });
      },

      generateSlug(text) {
        return text
          .toString()
          .toLowerCase()
          .replace(/\s+/g, '-')           // Reemplaza espacios con -
          .replace(/[^\w-]+/g, '')        // Elimina caracteres no deseados
          .replace(/--+/g, '-')           // Reemplaza múltiples - con uno solo
          .replace(/^-+/, '')             // Elimina - al inicio
          .replace(/-+$/, '');            // Elimina - al final
      },

      loadMore() {
        this.currPage++;
        this.getCategories();
      },
    },
    mounted() {
      this.getCategories();
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";
  #categories-page {
    background-image: $interactive-bg;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    min-height: 100vh;
  }

  .categories-content {
    padding-bottom: 2rem;
  }

  .link {
    &:hover {
      text-decoration: none;
      // -webkit-text-stroke: 1px $primary-color;
    }
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {
    .heading {
      margin-top: 2rem;
    }
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    .heading {
      margin-top: 2rem;
    }
  }
  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    .heading {
      margin-top: 4rem;
    }
  }
  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {
    #categories-page {
      padding-left: 5rem;
    }
    .categories-content {
      margin: 0 auto;
      width: 50em;
    }
    .heading {
      margin-top: 0;
    }
  }
  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    #categories-page {
      padding-left: 5rem;
    }
    .categories-content {
      margin: 0 auto;
      width: 70em;
    }
  }
  @media only screen and (min-width: $special) {
    .categories-content {
      margin: 0 auto;
      width: 70em;
    }
  }
  @media only screen and (min-width: $xl2-min) {
    .categories-content {
      margin: 0 auto;
      width: 80em;
    }
  }
  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {
    .categories-content {
      margin: 0 auto;
      width: 85em;
    }
  }
</style>